
        <div class="content">
            <div class="row">
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center icon-warning">
                          <i class="nc-icon nc-globe text-warning"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category">Transactions</p>
                          <p class="card-title">{{transactionsTotalNumber.totalDonations | json}}
                            <p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-refresh"></i> Update Now
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center icon-warning">
                          <i class="nc-icon nc-money-coins text-success"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category">Donatations</p>
                          <p class="card-title">$ {{totalDonationsAmount.totalDonations | json}}
                            <p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-calendar-o"></i> Last day
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-5 col-md-4">
                        <div class="icon-big text-center icon-warning">
                          <i class="nc-icon nc-favourite-28 text-primary"></i>
                        </div>
                      </div>
                      <div class="col-7 col-md-8">
                        <div class="numbers">
                          <p class="card-category">Users</p>
                          <p class="card-title">{{totalUsers.totalUsers | json}}
                            <p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-refresh"></i> Update now
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card ">
                  <div class="card-header ">
                    <h5 class="card-title">Donatations</h5>
                    <p class="card-category">Monthly</p>
                  </div>
                  <div class="card-body ">
                    <canvas baseChart 
                [datasets]="donationsByMonth.dataset"
                [labels]="donationsByMonth.labels"
                [options]="dollarOptions"
                [plugins]="[]"
                [legend]="true"
                [chartType]="'line'">
              </canvas>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-history"></i> Updated awhile ago
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="row">
              <div class="col-md-8">
                <div class="card card-chart">
                  <div class="card-header">
                    <h5 class="card-title">Users Registered</h5>
                    <p class="card-category">Monthly</p>
                  </div>
                  <div class="card-body">
                    <canvas baseChart 
                [datasets]="usersByMonth.dataset"
                [labels]="usersByMonth.labels"
                [options]="{responsive: true}"
                [plugins]="[]"
                [legend]="true"
                [chartType]="'bar'">
              </canvas>
                  </div>
                  <div class="card-footer">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-history"></i> Updated awhile ago
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card ">
                  <div class="card-header ">
                    <h5 class="card-title">Participants VS Teams</h5>
                    <p class="card-category">Based on Donatations</p>
                  </div>
                  <div class="card-body ">
                    <canvas baseChart 
          [datasets]="donationsbyType.dataset"
          [labels]="donationsbyType.labels"
          [options]="{responsive: true, tooltips: dollarSignTooltip}"
          [plugins]="[]"
          [legend]="true"
          [chartType]="'pie'">
        </canvas>
                  </div>
                  <div class="card-footer ">
                    <hr>
                    <div class="stats">
                      <i class="fa fa-history"></i> Updated awhile ago
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
        </div>
  