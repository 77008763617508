import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Title } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root',
  })
  export class AppService {

    profileLoading = false;

    constructor(private httpService: HttpService, private titleService: Title){
      
    }

    setPageTitle(title){
      this.titleService.setTitle(`${title} - Virtual Food Drive`);
    }
      
    isLoggedIn(){
        return !!localStorage.getItem('token');
    }

    getTeamProfileId(){
        if(!this.isLoggedIn()){
          return false;
        }
        if(!localStorage.getItem('profile') && !this.profileLoading){
          this.profileLoading = true;
          this.httpService.getCurrentParticipant().subscribe();
          return false;
        } else {
          try{
            const profile = JSON.parse(localStorage.getItem('profile'));
            return profile.ownedTeamId;
          }catch(e){
  
          }
        }
        return false;
    }
  }