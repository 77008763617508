import { Component, Input, OnInit } from '@angular/core';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { HttpService } from 'src/app/Service/http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  constructor(private http: HttpService,private router: Router, private toastr: ToastrService, private appService: AppService) { }

  userIcon = faUser;
  lockIcon = faLock;

  loginForm = new FormGroup({
    email: new FormControl('',[Validators.required]),
    password: new FormControl('',[Validators.required])
  });

  @Input()
  verified = false;

  ngOnInit(): void {
    this.appService.setPageTitle('Login');
  }
  onSubmit(){
    if(this.loginForm.invalid){
      this.loginForm.markAllAsTouched();
      return;
    }
    this.http.login(this.loginForm.value).subscribe((result : any)=>{
      
      if(result.success){
        this.toastr.success("Login successfuly");
        localStorage.setItem('token',result.token);
        localStorage.setItem('admin', result.admin);

        if(this.verified){
            this.router.navigate(['register', 'team']);
        }else{
          this.http.getCurrentParticipant().subscribe((result: any)=>{
            if(result.goal == null){
              this.router.navigate(['register', 'team']);
            }
            else if(result.ownedTeamId){
              this.router.navigate(['/participant', 'current', result.ownedTeamId]);
            }else{
              this.router.navigate(['/participant', 'current']);
            }
  
          });
        }


      }
    },error=>{
      this.toastr.error(error.error.message);
    })
  }

}
