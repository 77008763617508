import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }
  timeline = [
    { year : "Fall 1989",
      content: "Members of Eden United Church notice there are local hungry families and start an informal food bank.",
     direction: 'left' },
     { year : "Spring 1990",
     content: "The food bank becomes an official program the church",
     direction: 'right'},
      { year : "2002",
      content: "This volunteer-run program is incorporated and becomes an officially registered charity",
      direction: 'left' },
      {
        content: "The program becomes overwhelming because of the need and donations volunteers wonder if they should just close",
        direction: "center"
      },
      { year : "2005",
      content: "A part-time manager is hired. A part-time staff person joins at the end of the year.",
      direction: 'right' },
      {
        content: "The food bank expands its service area blind Meadowvale to include L5N & L5M postal codes",
        direction: "center"
      },
      { year : "2007",
      content: "Name changed to Eden community Food Bank" ,
      direction: 'left'},
      { year : "2009",
      content: "Unity Drive location is opened to serve L5L & L5K postal codes.",
      direction: 'right' },
      { year : "2010",
      content: "First Executive Director is hired" ,
      direction: 'left'},
      { year : "2011",
      content: "We intentionally focus on food through the Food Bank",
      direction: 'right' },
      { year : "2012",
      content: "Meadowvale Neighbours’ Cafe begins eat Battleford client centre" ,
      direction: 'left'},
      { year : "2013",
      content: " Learning Kitchen officially launched",
      direction: 'right' },
      { year : "January 2014",
      content: "Fresh Produce Box Program Begins" ,
      direction: 'left'},
      { year : "Spring 2014",
      content: "New Vision & Mission statements announced",
      direction: 'right' },
      { year : "27 May 2015",
      content: " Eden is announced as the new name to better reflect all the work we do",
      direction: 'end' },
  ]
  ngOnInit(): void {}
}
