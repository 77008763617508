import { Component, OnInit } from '@angular/core';
import { HttpService } from '../Service/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';
import { isNumber } from 'util';
import { TranslateService } from '@ngx-translate/core';

declare const paypal;

@AutoUnsub()
@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  public payPalConfig?;

  participant: any = {};
  currentParticipant = null;
  participantId;
  teamId;
  subs = [];

  formGroup = new FormGroup({
    participantId: new FormControl(null),
    teamId: new FormControl(null),
    items: new FormControl('{}'),
    transactionId: new FormControl(null),
    paymentType: new FormControl('paypal'),
    amount: new FormControl('', [Validators.required, this.notZero]),
    email: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    streetAddress: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl(null, [Validators.required]),
    country: new FormControl(''),
    postalCode: new FormControl('', [Validators.required]),
    organization: new FormControl(''),
    transactionResponse: new FormControl('')

  }, )

  notZero(formControl: FormControl) {
    if (formControl.value && isNaN(formControl.value)) {
      return { invalid: true };
    }
    if (!!formControl.value && formControl.value <= 0) {
      return { invalid: true };
    }
    return;
  }





  products = [];

  constructor(private route: ActivatedRoute, private httpService: HttpService, private router: Router, private appService: AppService, private translateService: TranslateService) {


    //
    this.translateService.get('donate.products').subscribe(result=>{
      this.products = result;
    });



    this.subs.push(this.route.params.subscribe(params => {
      this.participantId = params['id'];
      this.teamId = params['teamId'];
      if (this.participantId && !this.teamId) {
        this.subs.push(this.httpService.getParticipantById(this.participantId).subscribe(response => {
          this.participant = response;
        }))
      } else {
        this.subs.push(this.httpService.getTeamById(this.teamId).subscribe(response => {
          this.participant = response;
        }));
      }

      this.subs.push(this.httpService.getCurrentParticipant().subscribe((result: any) => {
        if (result &&  result.id) {
          this.currentParticipant = result;
          this.appService.setPageTitle('Donate - ' + this.getName());
          this.formGroup.controls.firstName.setValue(this.currentParticipant.firstName);
          this.formGroup.controls.lastName.setValue(this.currentParticipant.lastName);
          this.formGroup.controls.email.setValue(this.currentParticipant.email);
        }
      }));

    }));
  }

  ngOnInit(): void {
    this.donateNow();
  }

  getName() {
    if (this.teamId) {
      return this.participant.name;
    } else {
      return this.participant.firstName + ' ' + this.participant.lastName;
    }
  }

  toggle(product: any) {
    product.checked = !product.checked;
    this.calculateTotal();
  }

  calculateTotal() {
    let total = 0;
    this.products.forEach((product: any) => {
      if (product.checked) {
        total += Number(product.amount);
      }
    });
    this.formGroup.controls.amount.setValue(total);

  }

  donateNow() {
    paypal.Buttons({
      onInit: (data, actions) => {
        actions.disable();
        this.formGroup.statusChanges.subscribe(value => {

          if (this.formGroup.invalid) {
            actions.disable();
          } else {
            actions.enable();
          }

        });
      },
      onClick: (data, actions) => {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();
      },
      createOrder: (data, actions) => {
        console.log(this.formGroup)
        return actions.order.create({
          payer: {
            name: {
              given_name: this.formGroup.controls.firstName.value,
              surname: this.formGroup.controls.lastName.value
            },
            address: {
              address_line_1: this.formGroup.controls.streetAddress.value,
              admin_area_1: this.formGroup.controls.state.value,
              postal_code: this.formGroup.controls.postalCode.value,
              country_code: "CA",
              admin_area_2: this.formGroup.controls.city.value,
            },
            email_address: this.formGroup.controls.email.value,

          },
          purchase_units: [{
            name: {
              given_name: this.formGroup.controls.firstName.value,
              surname: this.formGroup.controls.lastName.value
            },
            amount: {
              value: this.formGroup.controls.amount.value
            }
          }],
          application_context: { shipping_preference: 'NO_SHIPPING'}
        });
      },
      onApprove: (data, actions) => {
        // This function captures the funds from the transaction.
        return actions.order.capture().then((details) => {
          console.log(details);
          this.formGroup.controls.transactionId.setValue(details.id);
          this.formGroup.controls.items.setValue(JSON.stringify(this.products.filter((product: any) => product.checked)))
          this.formGroup.controls.transactionResponse.setValue(JSON.stringify(details));
          if (this.teamId) {
            this.formGroup.controls.teamId.setValue(Number(this.teamId));
          } else {
            this.formGroup.controls.participantId.setValue(Number(this.participantId));
          }
          this.httpService.createDonation(this.formGroup.value).subscribe(response => {
            this.router.navigate(['thank-you']);
          });

        });
      }
    }).render('#paypal-button-container');
  }


}
