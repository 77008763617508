<div class="row">
    <div class="col-12">
        <div class="card card-user">
            <div class="image">
                <img src="{{getCover(participant)}}" alt="{{'images.participant-profile.alt-cover' | translate}}"
                    width="100%" />
                <label role="link" class=" btn-info btn-round cover-btn" id="cover-label"
                    for="cover">{{'participant-profile.upload-cover' | translate}}</label>
                <input type="file" id="cover" name="cover" (change)="updatePhoto($event, 'cover')" accept="image/*"
                    class="file-upload" />
            </div>
            <div class="card-body">
                <div class="author">
                    <img class="avatar border-gray" src="{{getImage(participant)}}"
                        alt="{{'images.participant-profile.alt-profile-picture' | translate}}" />
                    <div class="p-image">
                        <label role="link" class="pointer" id="pp-label" href="javascript:void(0);" for="profilePicture"><i
                                class="nc-icon nc-camera-compact"></i></label>
                        <input type="file" id="profilePicture" accept="image/*" name="profilePicture"
                            (change)="updatePhoto($event, 'profilePicture')" class="file-upload" />
                    </div>

                    <h5 class="title">{{'participant-profile.welcome' | translate}}
                        {{getName()}}{{'participant-profile.virtual-drive' | translate}}</h5>
                    <p class="description text-center"> {{participant.description}}</p>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-12">
                            <span class="pull-left">${{participant.total}}
                                {{'participant-profile.raised' | translate}}</span>
                            <span class="pull-right">${{participant.goal}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="progress">
                                <div [attr.data-percentage]="getPercentage()" [ngStyle]="{width: getPercentage() }"
                                    class="progress-bar progress-bar-success" role="progressbar" aria-valuemin="0"
                                    aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row row-margin">
                        <div class="update ml-auto mr-auto">
                            <button class=" btn-primary btn-round"
                                (click)="donate()">{{'mainmenu.tab.donate' | translate}}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-3 col-sm-12" *ngIf="participant.members">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{'participant-profile.team-members' | translate}}</h4>
            </div>
            <div class="card-body">
                <ul class="list-unstyled team-members">
                    <li *ngFor="let individual of participant.members;let i=index;">
                        <div class="row">
                            <div class="col-md-2 col-2">
                                <div class="avatar">
                                    <img src="{{getImage(participant.members)}}" alt="Circle Image"
                                        class="img-circle img-no-padding img-responsive">
                                </div>
                            </div>
                            <div class="col-md-9 col-9">

                                <a (click)="goProfile(individual.id, 'individual')" class="pointer"
                                    href="javascript:void(0);">{{individual.firstName}}
                                    {{individual.lastName}}</a>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div [ngClass]="{'col-md-9' : !!participant.members, 'col-md-12': !participant.members}" class="col-sm-12"
        *ngIf="participant.canEdit">
        <div class="row">
            <div class="col-12 form-group">
                <textarea class="form-control textarea" [(ngModel)]="participant.description"></textarea>
            </div>
        </div>
        <div class="row row-margin">
            <div class="col-12 text-center">
                <button class=" btn-primary btn-round"
                    (click)="updateDescription()">{{'participant-profile.update-description' | translate}}</button>
            </div>
        </div>
    </div>