<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-md-8 offset-md-2 col-sm-12">
            <div class="row">
                <div class="col-12">
                    <h2>Reset your password</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="email">Email</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <!-- <div class="input-group-text"><fa-icon [icon]="userIcon"></fa-icon></div> -->
                        </div>
                        <input type="email" class="form-control" name="email" id="email" placeholder="Email" formControlName="email">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-error-message  ngDefaultControl [formControl]="formGroup.controls.email" [messages]="{required: 'Email is requried'}"></app-error-message>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="passowrd">Password</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <!-- <div class="input-group-text"><fa-icon [icon]="userIcon"></fa-icon></div> -->
                        </div>
                        <input type="password" class="form-control" name="passowrd" id="passowrd" placeholder="Password" formControlName="password">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-error-message  ngDefaultControl [formControl]="formGroup.controls.password" [messages]="{required: 'Password is requried'}"></app-error-message>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="passowrd">Password Confirmation</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <!-- <div class="input-group-text"><fa-icon [icon]="userIcon"></fa-icon></div> -->
                        </div>
                        <input type="password" class="form-control" name="password_confirmation" id="password_confirmation" placeholder="Password Confirmation" formControlName="password_confirmation">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-error-message  ngDefaultControl [formControl]="formGroup.controls.password_confirmation" [messages]="{required: 'Password is requried'}"></app-error-message>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-lg btn-primary" (click)="submit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
    
    
    </form>