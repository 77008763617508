<div class="row">
  <div class="col-md-6 offset-md-3 text-center">
    <h3>{{ 'find.donation-text'  | translate}} <a href="https://edenffc.org/donate-now" target="_blank">{{'find.donation-link' | translate}}</a></h3>
  </div>
</div>
<div class="row" id="search-bar">
  <div class="col-md-8 offset-md-2 col-sm-12">


    <div class="row justify-content-center">
      <div class="col-12 col-md-10 col-lg-8">
          <form class="card card-sm">
              <div class="card-body row no-gutters align-items-center">
                  <div class="col-auto">
                      <i class="fas fa-search h4 text-body"></i>
                  </div>
                  <!--end of col-->
                  <div class="col">
                      <input class="form-control form-control-lg form-control-borderless" name="search" [(ngModel)]="text" type="search" placeholder="{{'find.search-label' | translate}}">
                  </div>
                  <!--end of col-->
                  <div class="col-auto">
                      <button class="btn btn-lg btn-success"(click)="search()" type="submit">{{'find.search-button' | translate}}</button>
                  </div>
                  <!--end of col-->
              </div>
          </form>
      </div>
      <div class="col-12 col-md-10 col-lg-8 text-center" *ngIf="isLoggedIn()">
        <a [routerLink]="['/register/team']">{{'find.create-team' | translate}}</a>
      </div>
      <!--end of col-->
  </div>





  </div>
</div>
<div class="row row-margin text-center">
  <div class="card col-md-4 offset-md-4 col-sm-12" *ngIf="result.length == 0">
    <h2>{{'find.no-profile' | translate}}</h2>
  </div>
  <div class="card col-md-2 col-sm-6 search-result" *ngFor="let item of result">
    <a [routerLink]="[toToProfile(item)]">
      <img class="card-img-top" src="{{getImage(item)}}" alt="{{'images.find.alt' | translate}}">
      <div class="card-body">
        <h4>{{getName(item)}}</h4>
        <!-- <p class="card-text">{{item.description | slice:0:100}}</p> -->
      </div>
    </a>
  </div>
</div>