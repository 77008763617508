<div class="slider row  justify-content-center " style="background-image: url({{'images.home.src-banner' | translate}});">
  <div class="col-md-4  col-sm-2 my-auto text-center">
    <div class="slider-caption ">
      <img src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}" width="100rem">
      <h3>{{'home.slider.caption' | translate}}</h3>

      <a class="btn btn-primary" [routerLink]="['/register']" *ngIf="!token">{{'home.slider.button.register'| translate}}</a>
      <a class="btn btn-primary" [routerLink]="['/find']" *ngIf="token">{{'home.slider.button.donate' | translate}}</a>

    </div>
  </div>
</div>

<div class="scoreborad row">
  <div class="col-lg-6 col-md-6">
    <div class="card card-plain">
      <div class="card-header"><h4 class="card-title"> {{'home.scoreboard.top-team' | translate}}</h4><p class="card-category"> {{'home.scoreboard.top-team.subtitle' | translate}}</p></div>
    <table class="table table-hover table-striped table-dark">
      <thead>
        <tr>
          <th scope="col" >{{'home.scoreboard.top-team' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let team of teams;let i=index;" (click)="goProfile(team.id, 'team')" class="pointer">
          <th scope="row">{{i+1}}</th>
          <td>{{team.name}}</td>
          <td>${{team.total}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
  <div class="col-lg-6 col-md-6">
    <div class="card card-plain">
      <div class="card-header"><h4 class="card-title"> {{'home.scoreboard.top-individual' | translate}}</h4><p class="card-category"> {{'home.scoreboard.top-individual.subtitle' | translate}}</p></div>
    <table class="table table-hover table-striped table-dark">
      <thead>
        <tr>
          <th scope="col">{{'home.scoreboard.top-individual' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let individual of individuals;let i=index;" (click)="goProfile(individual.id, 'individual')"
          class="pointer">
          <th scope="row">{{i+1}}</th>
          <td>{{individual.name}}</td>
          <td>${{individual.total}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="about-us row">
  <div class="col-md-6 col-sm-12">
    <h2>{{'home.about-us.title' | translate}}</h2>
    <p>{{'home.about-us.blurb1' | translate}}</p>
    <p>{{'home.about-us.blurb2' | translate}}</p>
  </div>
  <div class="col-md-6 col-sm-12 about-us-image text-center" style="background-image: url({{'images.home.src-logo-background' | translate}});">
    <img src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}" style="background-color: white; padding: 1rem;"  width="45%">
  </div>
</div>