import { Component, OnInit } from '@angular/core';
import { HttpService } from '../Service/http.service';
import { Router } from '@angular/router';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';


@AutoUnsub()
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {


  constructor(private httpService: HttpService, private router: Router, private appService: AppService) { 
    
  }
  teams = [{}];
  individuals = [{}];
  token;
  ngOnInit(): void {

    //Set Page Title
    this.appService.setPageTitle('Home');

    this.httpService.getAnalytics().subscribe((result: any)=>{
      console.log(result);
      this.teams = result.teams;
      this.individuals = result.participants;
    });

    //getTokenIfFound
    this.token = localStorage.getItem('token');
  }
  goProfile(id, type){
    (type == 'team') ? this.router.navigate(['participant','teams',id]) : this.router.navigate(['participant/',id]);
  }
}
