<div class="card text-center">
    <div class="card-header">
        <h3 class="card-title">{{'registeration-form.title' | translate}}</h3>
    </div>
    <div class="card-body">
        <form [formGroup]="registrationForm">
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="fullName">{{'form.full-name' | translate}}</label>
                    <div class="input-group mb-2">

                        <input type="text" class="form-control" id="fullName" ngDefaultControl placeholder="{{'form.full-name' | translate}}"
                            formControlName="fullName">
                    </div>
                </div>
            </div>
            <app-error-message ngDefaultControl [formControl]="registrationForm.controls.fullName"
                [messages]="{required: 'error.full-name-required' | translate, fullname: 'error.full-name' | translate}">
            </app-error-message>
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="password">{{'form.password' | translate}}</label>
                    <div class="input-group mb-2">

                        <input type="password" class="form-control" id="password" ngDefaultControl
                            placeholder="{{'form.password' | translate}}" formControlName="password">
                    </div>
                </div>
            </div>
            <app-error-message ngDefaultControl [formControl]="registrationForm.controls.password"
                [messages]="{required: 'error.password' | translate,minlength: 'error.password-length' | translate}">
            </app-error-message>
            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="email">{{'form.email' | translate}}</label>
                    <div class="input-group mb-2">
                        <input type="email" class="form-control" id="email" placeholder="{{'form.email' | translate}}" ngDefaultControl
                            formControlName="email">
                    </div>
                </div>
            </div>
            <app-error-message ngDefaultControl [formControl]="registrationForm.controls.email"
                [messages]="{required: 'error.email' | translate}"></app-error-message>
            <div class="row">
                <div class="col-12">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="acceptPolicy" ngDefaultControl
                            formControlName="acceptPolicy">
                        <label class="form-check-label" for="acceptPolicy">{{'registeration-form.accept-text' | translate}} <a href="#">{{'registeration-form.accept-link' | translate}}</a>{{'registeration-form.accept-rest-text' | translate}}</label>
                    </div>
                </div>

            </div>
            <app-error-message ngDefaultControl [formControl]="registrationForm.controls.acceptPolicy"
                [messages]="{required: 'registeration-form.accept-error' | translate, policy:'registeration-form.policy-error' | translate}">
            </app-error-message>
            <div class="row">
                <div class="col-12">
                    
                    <button type="submit" class="btn btn-primary " (click)="onSubmit()">{{'registeration-form.button' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>