import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  baseURL = "/api";


  data : any  = {
    '/participants/current': null
  }

  constructor(private http: HttpClient,) { }
  login(user){
    return this.http.post(this.baseURL + '/user/login',user);
  }
  register(particpant){
      return this.http.post(this.baseURL + '/user/register',particpant);
  }

  getParticipantById(id){
      if(id == 'current'){
        return this.getCurrentParticipant();
      }
      return this.http.get(this.baseURL + '/participants/'+id);
  }

  getTeamById(id){
    return this.http.get(this.baseURL + '/teams/'+id);
  }

  getAnalytics(){
    return this.http.get(this.baseURL + '/donations/analytics/');
  }

  getCurrentParticipant(){
    return this.http.get(this.baseURL + '/participants/current').pipe(map(data=> {
      try{
        localStorage.setItem('profile', JSON.stringify(data));
      }catch(e){

      }
      return data;
    }));
  }

  getTeamsByName(name){
    return this.http.get(this.baseURL + '/teams?name='+name);
  }

  getParticipantsByName(name){
    return this.http.get(this.baseURL + '/participants?name='+name);
  }

  updateParticipant(data, id){
    return this.http.put(this.baseURL + '/participants/'+id, data);
  }

  createTeam(data){
    return this.http.post(this.baseURL+ '/team/create', data);
  }

  createDonation(data){
    return this.http.post(this.baseURL + '/donation/create', data);
  }

  updateImage(form, type, id, isTeam = false){
    if(isTeam){
      return this.http.post(this.baseURL+ '/teams/'+id+'/'+ type,form);
    }else{
      return this.http.post(this.baseURL+ '/participants/'+id+'/'+ type,form);    
    }
  }

  forgotPassowrd(data){
    return this.http.post(this.baseURL + '/password/forgot', data);
  }

  resetPassword(data){
    return this.http.post(this.baseURL + '/password/reset', data);
  }


  updateProfile(data, id, isTeam = false){
    if(isTeam){
      return this.http.put(this.baseURL + '/teams/'+ id, data);
    }else{
      return this.updateParticipant(data, id);
    }
  }

  getDonations(page = 1){
    return this.http.get(`/api/admin/donation?page=${page}`);
  }

  getAdminAnalytics(){
    return this.http.get(`/api/admin/analytics`);
  }

  getUsers(page = 1){
    return this.http.get(`/api/admin/users?page=${page}`);
  }

}