import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '../Service/http.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';


@AutoUnsub()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  formGroup : FormGroup = new FormGroup({
    email: new FormControl('',[Validators.required]),
    password: new FormControl('',[Validators.required]),
    password_confirmation: new FormControl('',[Validators.required]),
    token: new FormControl('',[Validators.required])
  })

  constructor(private httpService: HttpService, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private appService: AppService) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.formGroup.controls.token.setValue(params['token']);
    });


  }

  ngOnInit(): void {
    this.appService.setPageTitle('Reset Password');
  }


  submit(){
    this.formGroup.markAllAsTouched();
    console.log(this.formGroup.value);
    if(this.formGroup.valid){
      const data = this.formGroup.value;
      this.httpService.resetPassword(this.formGroup.value).subscribe((result: any)=>{
        if(result.success){
          this.toastr.success(result.msg);
        }else{
          this.toastr.error(result.msg);
        }
      });
    }
  }

}
