import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  showHeader = true;
  showFooter = true;
  showSidebar =  false;

  onActivate(event) {
    window.scroll(0,0);
  }
  langs = ['en', 'fr'];
  constructor(private router: Router, private translateService: TranslateService, private activatedRoute: ActivatedRoute){}
  ngOnInit(): void {
    let browserlang= this.translateService.getBrowserLang();
    let lang = localStorage.getItem("lang");
    if(lang){
      this.translateService.setDefaultLang(lang);
    }
    else if (this.langs.indexOf(browserlang) > -1) {
      this.translateService.setDefaultLang(browserlang);
    } else {
      this.translateService.setDefaultLang('en');
    }
    this.setupRoutes();
  }

  setupRoutes(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(this.activatedRoute.firstChild.snapshot.data.showHeader === undefined){
          this.showHeader = true;
        }else{
          this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader;
        }

        if(this.activatedRoute.firstChild.snapshot.data.showSidebar === undefined){
          this.showSidebar = false;
        }else{
          this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar;
        }

        if(this.activatedRoute.firstChild.snapshot.data.showFooter === undefined){
          this.showFooter = true;
        }else{
          this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter;
        }
      }
    });

  }
}
