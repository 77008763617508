import { isArray } from 'util'

export const AutoUnsub = () => {
    return function(constructor) {
        const orig = constructor.prototype.ngOnDestroy
        constructor.prototype.ngOnDestroy = function() {
            for(const prop in this) {
                const property = this[prop]
                unsubscribe(property);
                if(isArray(property)){
                    property.forEach(item=>{
                        unsubscribe(item);
                    })
                }
            }
            orig.apply()
        }
    }
}

const unsubscribe = item =>{
    if(typeof item.subscribe === "function") {
        !item.closed() && item.unsubscribe();
    }
}