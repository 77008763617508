<footer class="section footer-classic context-dark bg-image">
    <div class="container">
      <div class="row row-30">
        <div class="col-md-4 col-xl-5">
          <div class="pr-xl-4"><a class="brand" [routerLink]="['/']"><img class="brand-logo-light" src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}" height="100px" srcset="{{'images.logo.src' | translate}}"></a>
            <p>{{'footer.first-col.about' | translate}}</p>
            <!-- Rights-->
            <p class="rights"><span>©  </span><span class="copyright-year">{{'footer.copyright.year' | translate}}</span><span> </span><span>{{'footer.copyright.sitename' | translate}}</span><span>. </span><span>{{'footer.copyright.right-reserved' | translate}}</span></p>
            <p class="">{{'footer.made-with' | translate}} &hearts; <a target="_blank" href="https://kand.ca">KAND CA</a></p>
            <p>{{'footer.registeration-number.text'| translate}} {{'footer.registeration-number.number' | translate}}


            </p>
          </div>
        </div>
        <div class="col-md-4">
          <h5>{{'footer.contact-us.title' | translate}}</h5>
          <dl class="contact-list">
            <dt>{{'footer.contact-us.phone.label' | translate}}</dt>
            <dd>{{'footer.contact-us.phone.number' | translate}}</dd>
          </dl>
          <dl class="contact-list">
            <dt>{{'footer.contact-us.email.label' | translate}}</dt>
            <dd><a href="mailto:info@edenffc.org">{{'footer.contact-us.email.email' | translate}}</a></dd>
          </dl>
          <dl class="contact-list">
            <dt>{{'footer.contact-us.address.label' | translate}}</dt>
            <dd>
              {{'footer.copyright.sitename' | translate}}<br/>
              {{'footer.contact-us.address.line1' | translate}}<br/>
              {{'footer.contact-us.address.line2' | translate}}<br/>
            </dd>
          </dl>
          <dl class="contact-list">
            <dt>{{'footer.contact-us.hours.label' | translate}}
              </dt>
              <dd>{{'footer.contact-us.hours.line1' | translate}}</dd>
          </dl>
        </div>
        <div class="col-md-4 col-xl-3">
          <h5>{{'footer.contact-us.quick-links.label' | translate}}</h5>
          <ul class="nav-list">
            <li><a [routerLink]="['/']">{{'mainmenu.tab.home' | translate}}</a></li>
            <li><a [routerLink]="['/find']">{{'mainmenu.tab.donate' | translate}}</a></li>
            <li><a [routerLink]="['/register']">{{'mainmenu.tab.register' | translate}}</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row no-gutters social-container">
      <div class="col"><a class="social-inner" href="{{'footer.social-facebook' | translate}}"><span class="icon mdi mdi-facebook"></span><span>Facebook</span></a></div>
      <div class="col"><a class="social-inner" href="{{'footer.social-twitter' | translate}}"><span class="icon mdi mdi-twitter"></span><span>twitter</span></a></div>
    </div>
  </footer>