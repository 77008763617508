<div class="row">
    <div class="col-md-12">
        <h2 class="text-center">{{'donate.title' | translate}}</h2>
        <h2 class="text-center">{{getName()}}</h2>
        <!-- <p class="text-center">Want to skip using the shopping cart? <a>Click here to enter a direct donation amount instead</a>.</p> -->
    </div>
</div>
<div class="row">
    <div class="col-md-3" *ngFor="let product of products; let i = index">
        <div class="form-check product">
            <input class="form-check-input" type="checkbox" value="" id="product{{i}}" (click)="toggle(product)">
            <label class="form-check-label" for="product{{i}}" >

              <div class="card bg-dark text-white">
                <img src="{{product.image}}" class="card-img">
                <div class="card-img-overlay">
                  <h5 class="card-title text-center">{{product.title}}</h5>
                  <p class="card-text text-center"><small>{{product.description}}</small></p>
                  <h5 class="card-text text-center">${{product.amount}}</h5>
                </div>
              </div>

              <h5 class="card-text text-center">${{product.amount}}</h5>
            </label>
        </div>
    </div>
</div>
<div class="row form-row">
    <div class="col-md-8 offset-md-2 col-sm-12" [formGroup]="formGroup">
        <h3 class="text-center">{{'donate.donation-total' | translate}}</h3>
        <input class="form-control" formControlName="amount"/>
        <app-error-message  ngDefaultControl [formControl]="formGroup.controls.amount" [messages]="{invalid: 'error.min-amount' | translate, required: 'error.amount-required' | translate}"></app-error-message>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <h3 class="text-center">{{'form.title' | translate}}</h3>
    </div>
</div>
<div class="row">
    <div class="col-8 offset-2">
        <form [formGroup]="formGroup">
            <div class="row form-row">
              <div class="col-md-6 col-sm-12">
                <input type="text" formControlName="firstName" class="form-control" placeholder="{{'form.first-name' | translate}}">
                <app-error-message  ngDefaultControl [formControl]="formGroup.controls.firstName" [messages]="{required: 'error.first-name' | translate}"></app-error-message>
              </div>
              <div class="col-md-6 col-sm-12">
                <input type="text" formControlName="lastName" class="form-control" placeholder="{{'form.last-name' | translate}}">
                <app-error-message  ngDefaultControl [formControl]="formGroup.controls.lastName" [messages]="{required: 'error.last-name' | translate}"></app-error-message>
              </div>
            </div>
            <div class="row form-row">
                <div class="col-md-6 col-sm-12">
                  <input type="text" formControlName="streetAddress" class="form-control" placeholder="{{'form.address' | translate}}">
                  <app-error-message  ngDefaultControl [formControl]="formGroup.controls.streetAddress" [messages]="{required: 'error.address' | translate}"></app-error-message>
                </div>
                <div class="col-md-6 col-sm-12">
                  <input type="text" formControlName="city" class="form-control" placeholder="{{'form.city' | translate}}">
                  <app-error-message  ngDefaultControl [formControl]="formGroup.controls.city" [messages]="{required: 'error.city' | translate}"></app-error-message>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-md-6 col-sm-12">
                  <select formControlName="state" name="provience" class="form-control">
                    <option [value]="null">{{'form.select' | translate}}</option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </select>
                  <app-error-message  ngDefaultControl [formControl]="formGroup.controls.state" [messages]="{required: 'error.province' | translate}"></app-error-message>
                </div>
                <div class="col-md-6 col-sm-12">
                  <input type="text" formControlName="postalCode" class="form-control" placeholder="{{'form.postal-code' | translate}}">

                  <app-error-message  ngDefaultControl [formControl]="formGroup.controls.postalCode" [messages]="{required: 'error.postal-code' | translate}"></app-error-message>
                </div>
            </div>
            <div class="row form-row">
                <div class="col-md-6 col-sm-12">
                    <input type="text" formControlName="email" class="form-control" placeholder="{{'form.email' | translate}}">
                    <app-error-message  ngDefaultControl [formControl]="formGroup.controls.email" [messages]="{required: 'error.email' | translate}"></app-error-message>
                  </div>
                <div class="col-md-6 col-sm-12">
                  <input type="text" formControlName="organization" class="form-control" placeholder="{{'form.organization-name' | translate}}">
                </div>
            </div>
  

        </form>
    </div>
</div>
<div class="row">
    <div class="col-md-8 offset-md-2 col-sm-12">
        <p class="text-center" style="color: red;" *ngIf="formGroup.touched && formGroup.invalid">
          {{'error.general' | translate}}
        </p>
        <div id="paypal-button-container"></div>
    </div>
</div>