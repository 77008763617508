<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar></app-sidebar>
  </div>
  <div  class="main-panel">
    <navbar-cmp></navbar-cmp>
  
  <div class="content">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>
</div>



<ngx-spinner></ngx-spinner>