import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { ParticipantProfileComponent } from './participant/participant-profile/participant-profile.component';
import { PostRegisterComponent } from './post-register/post-register.component';
import { HomePageComponent } from './home-page/home-page.component';
import { DonateComponent } from './donate/donate.component';
import { FindComponent } from './find/find.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ForgetPassowrdComponent } from './forget-passowrd/forget-passowrd.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DonationsTransactionsComponent } from './admin/donations-transactions/donations-transactions.component';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './admin/users/users.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', redirectTo:'home','pathMatch': 'full'},
  { path: 'home', component: HomePageComponent},
  { path: 'register', component: RegisterComponent },
  { path: 'participant/:id/:teamId', component: ParticipantProfileComponent},
  { path: 'donate/:id/:teamId', component: DonateComponent},
  { path: 'donate/:id', component: DonateComponent},
  { path: 'participant/:id', component: ParticipantProfileComponent},
  { path: 'register/team', component: PostRegisterComponent},
  { path: 'find', component: FindComponent},
  { path: 'thank-you', component: ThankYouComponent},
  { path: 'forgot-password', component: ForgetPassowrdComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'about', component: AboutUsComponent},

  {path: 'admin', redirectTo: '/admin/dashboard', pathMatch: 'full'},
  { path: 'admin', component: AdminComponent ,data: { showHeader: false, showSidebar: true , showFooter: false} ,children:[
    { path: 'users/:id', component: UsersComponent ,data: { showHeader: false, showSidebar: true , showFooter: false} ,'pathMatch': 'full'} ,
    { path: 'donations/:id', component: DonationsTransactionsComponent ,data: { showHeader: false, showSidebar: true , showFooter: false} ,'pathMatch': 'full'} ,
    { path: 'dashboard', component: DashboardComponent, data: { showHeader: false, showSidebar: true , showFooter: false} , 'pathMatch': 'full'}
  ]},


];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]

})
export class AppRoutingModule { }
