import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {


  @Input()
  formControl: FormControl;

  @Input()
  messages : any = {};

  constructor() { }

  ngOnInit(): void {
  }



  showErrors(){
    if(this.formControl.errors){
      return true;
    }
    return false;
  }

  getError(){
    const error = Object.keys(this.formControl.errors)[0];
    return this.messages[error];
  }

}
