
<div class="sidebar-wrapper">
  <div class="logo">
    <a class="simple-text logo-mini">
      <div class="logo-image-small">
        <img src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}">
      </div>
    </a>
    <a href="/" class="simple-text logo-normal">
      Food Drive
    </a>
  </div>
    <ul class="nav">
        <li *ngFor="let menuItem of menuItems" routerLinkActive="active" class="{{menuItem.class}}">
            <a [routerLink]="[menuItem.path]">
                <i class="nc-icon {{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>