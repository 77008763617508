<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Users</h4>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead class="text-primary">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of data; let i = index">
                                <td>{{user.created_at}}</td>
                                <td>{{user.name}}</td>
                                <td>{{user.email}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item" *ngIf="page > 1"><a class="page-link"
                        [routerLink]="['/admin/users', page-1]">Previous</a></li>

                <li *ngFor="let item of [].constructor(pages); let i = index" class="page-item">
                    <a class="page-link" [routerLink]="['/admin/users',i+1]">{{i+1}}</a>
                </li>
                <li class="page-item" *ngIf="page < pages"><a class="page-link"
                        [routerLink]="['/admin/users',page+1]">Next</a></li>
            </ul>
        </nav>
    </div>
</div>