
<div class="row our-story">
    <div class="col-12 text-center">
        <h1>{{'about-us.title' | translate}}</h1>
        <h4>{{'about-us.subtitle' | translate}}</h4>
    </div>
    <div class="col-md-6 col-xs-12">
        <img src="{{'images.about-us.src' | translate}}" width="100%" alt="{{'images.about-us.alt' | translate}}">
    </div>
    <div class="col-md-6 col-xs-12 paragraph">
        <p>{{'about-us.blurb' | translate}}<span style="color: green;">{{'about-us.vision.line1' | translate}}</span></p>
    </div>
</div>

<div class="row parallax-two text-center">
    <div class="our-mission col-12">
        <h1>{{'about-us.mission.label' | translate}}</h1>
        <h3>{{'about-us.mission.line1' | translate}}</h3>
    </div>
    <div class="our-mission col-12">
        <h1>{{'about-us.vision.label' | translate}}</h1>
        <h3>{{'about-us.vision.line1' | translate}}</h3>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="text-center call-to-action">
            <h4>{{'about-us.timelinetext.title' | translate}}</h4>
        </div>
        <div class="timeline" *ngFor="let cell of 'about-us.timeline' | translate">
            <div class="contentContainer left" *ngIf="cell.direction == 'left'">
                <div class="content">
                    <h2>{{cell.year}}</h2>
                    <p>{{cell.content}}</p>
                </div>
            </div>
            <div class="contentContainerCenter center" *ngIf="cell.direction == 'center'">
                <div class="contentCenter">
                    <p>{{cell.content}}</p>
                </div>
            </div>
            <div class="contentContainer right" *ngIf="cell.direction == 'right'">
                <div class="content">
                    <h2>{{cell.year}}</h2>
                    <p>{{cell.content}}</p>
                </div>
            </div>
            <div class="contentContainerEnd center" *ngIf="cell.direction == 'end'">
                <div class="content">
                    <h2>{{cell.year}}</h2>
                    <img src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}" width="40%">
                    <p>{{cell.content}}</p>
                </div>
            </div>
        </div>
    </div>
</div>