import { Component, OnInit } from '@angular/core';
import { HttpService } from '../Service/http.service';
import { PROFILE_PICTURE, COVER_IMAGE } from '../constants';
import { Router } from '@angular/router';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';


@AutoUnsub()
@Component({
  selector: 'app-find',
  templateUrl: './find.component.html',
  styleUrls: ['./find.component.scss']
})
export class FindComponent implements OnInit {

  text;

  result = [];

  constructor(private httpService: HttpService, private router: Router, private appService: AppService) {

  }

  ngOnInit(): void {
    this.search('');
    this.appService.setPageTitle('Find Team/Individual');
  }

  search(value = this.text){

    this.httpService.getTeamsByName(value).subscribe((result: [])=>{
      this.result = result;
      this.httpService.getParticipantsByName(value).subscribe((result: [])=>{
        this.result   = this.result.concat(...result);
      });
    });

  }

  getName(item){
    if(item.name){
      return item.name;
    }else{
      return item.firstName + ' '+ item.lastName;
    }
  }

  getImage(participant){
    if(!participant.profilePicture){
      return PROFILE_PICTURE;
    }
    return participant.profilePicture
  }

  getCover(participant){
    if(!participant.cover){
      return COVER_IMAGE;
    }
    return participant.cover
  }

  toToProfile(item){
    if(item.name){
      return `/participant/teams/${item.id}`;
    }else{
      return  `/participant/${item.id}`;
    }
  }
  isLoggedIn(){
    return this.appService.isLoggedIn()
  }
}
