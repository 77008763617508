import { Component, OnInit } from '@angular/core';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@AutoUnsub()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private appService: AppService, private router: Router, private translateService: TranslateService) { }


  navbarCollapsed = true;

  isNavbarCollapsed = true;

  langs = ['en', 'fr'];
  admin : any = false;
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.navbarCollapsed = true;
      }
    });
  }

  isLoggedIn(){
    return this.appService.isLoggedIn();
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/']);
  }

  getTeamProfileId(){
    return this.appService.getTeamProfileId();
  }

  isAdmin(){
    return localStorage.getItem('admin') == 'true';
  }
 
 
  public useLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
    localStorage.setItem("lang", lang);
  }

}
