import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from './register/register.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegisterationFormComponent } from './register/registeration-form/registeration-form.component';
import { LoginFormComponent } from './register/login-form/login-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ParticipantProfileComponent } from './participant/participant-profile/participant-profile.component';
import { PostRegisterComponent } from './post-register/post-register.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { HomePageComponent } from './home-page/home-page.component';
import { DonateComponent } from './donate/donate.component';
import { FindComponent } from './find/find.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ForgetPassowrdComponent } from './forget-passowrd/forget-passowrd.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ToastrModule } from 'ngx-toastr';
import {  RecaptchaModule, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DonationsTransactionsComponent } from './admin/donations-transactions/donations-transactions.component';
import { AdminComponent } from './admin/admin.component';
import { HttpErrorInterceptor } from './interceptor/http.error.interceptor';
import { ChartsModule } from 'ng2-charts';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UsersComponent } from './admin/users/users.component';
import { NavbarComponent } from './admin/navbar/navbar.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';

export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    RegisterationFormComponent,
    LoginFormComponent,
    ParticipantProfileComponent,
    PostRegisterComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    DonateComponent,
    FindComponent,
    ThankYouComponent,
    ErrorMessageComponent,
    ForgetPassowrdComponent,
    ResetPasswordComponent,
    AboutUsComponent,
    DonationsTransactionsComponent,
    AdminComponent,
    SidebarComponent,
    UsersComponent,
    NavbarComponent,
    DashboardComponent  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 13000,
      positionClass: 'toast-bottom-right'
    }), // ToastrModule added,
    RecaptchaModule,
    RecaptchaV3Module,
    NgxGoogleAnalyticsModule.forRoot('UA-48073564-22'),
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    }),
    ChartsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: RECAPTCHA_V3_SITE_KEY ,useValue: '6LcsLMkZAAAAAIyt3z56yCKb9evtCn2M6BtwJyyj' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
