import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/Service/http.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {


  page = 1;

  data = [];

  pages = 1;

  subs = [];

  constructor(private route: ActivatedRoute, private httpServie: HttpService) {

  }

  ngOnInit(): void {
    this.subs.push(this.route.params.subscribe(params => {
      this.page = Number(params['id']);
      this.httpServie.getUsers(this.page).subscribe((result: any)=>{
        this.pages = Math.ceil(result.total/result.per_page);
        this.data = result.data;
      });
    }));
  }

}
