import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/Service/http.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dollarSignTooltip= {
   
  };

  dollarOptions = {
    responsive: true, tooltips: {...this.dollarSignTooltip}, scales: { yAxes: [{ ticks: { callback: function (value, index, values) { return '$' + value } } }] }
  }


  data: any = {
    usersByMonth: [],
    teamsCountVsParticipantCount: [],
    donationsbyType: [],
    donationsByMonth: []
  };

  usersByMonth: any = {
    labels: [],
    dataset: []
  }

  donationsByMonth: any = {
    labels: [],
    dataset: []
  }


  donationsbyType: any = {
    labels: [],
    dataset: []
  }
  transactionsTotalNumber;
  totalDonationsAmount;
  totalUsers;
  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.httpService.getAdminAnalytics().subscribe(result => {
      this.data = result;
      this.getUsersByMonth();
      this.getDonationsByMonth();
      this.getDonationsbyType();
      this.transactionsTotalNumber  = this.data.totalDonations;
      this.totalDonationsAmount  =  this.data.totalDonationsAmount;
      this.totalUsers = this.data.totalUsers;
    });
  }

  getUsersByMonth() {
    this.usersByMonth.labels = this.data.usersByMonth.map(item => item.month + '/' + item.year);
    this.usersByMonth.dataset = [{ data: this.data.usersByMonth.map(item => item.usersCount), label: 'User Per Month' }];
  }

  getDonationsByMonth() {
    this.donationsByMonth.labels = this.data.donationsByMonth.map(item => item.month + '/' + item.year);
    this.donationsByMonth.dataset = [{ data: this.data.donationsByMonth.map(item => item.amount), label: 'Donations $ Per Month' }];
  }

  getDonationsbyType() {
    this.donationsbyType.labels = ['Participants', 'Teams'];
    this.donationsbyType.dataset = [{ data: [this.data.donationsbyType[0].participantsDonations, this.data.donationsbyType[0].teamsDonations], label: 'Donations $ Per User Type' }];
  }

}
