import { Component, OnInit } from '@angular/core';
import { faDollarSign, faLock } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import { HttpService } from '../Service/http.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-post-register',
  templateUrl: './post-register.component.html',
  styleUrls: ['./post-register.component.scss']
})
export class PostRegisterComponent implements OnInit {


  dollarSign = faDollarSign;
  fees : string = '0.00';
  description : string = '';
  forms : Array<string> = ['individual', 'teamCreate', 'teamSearch'];
  individual : boolean = true;
  teamCreate : boolean = false;
  teamSearch : boolean = false;

  currentParticipant: any = {};

  selectedTeam: any = null;

  foundTeams = [];

  goalForm = new FormGroup({
    personalGoal : new FormControl(250, [Validators.required]),
    teamName : new FormControl('', [Validators.required]),
    teamGoal: new FormControl(500, [Validators.required]),
    searchTeam: new FormControl('', [Validators.required])
  });

  public model: any;

  


  constructor(private httpService: HttpService,  private toastr: ToastrService, private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setPageTitle('Post Registration');
    this.httpService.getCurrentParticipant().subscribe(result=>{
      this.currentParticipant = result;

    })
  }


  whichForm(ans){
    if(ans == 'TeamCreate') {
      this.teamCreate = true;
      this.teamSearch = false;
      this.individual = false;
      this.description = "Create New Team";
    }
    else if(ans == 'TeamSearch') {
      this.teamCreate = false;
      this.teamSearch = true;
      this.individual = false;
      this.description = "Search For a new Team"
    }else{
      this.teamCreate = false;
      this.teamSearch = false;
      this.individual = true;
    }
    this.updateDisabledFields();
  }
  onSubmit(ans){

  }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    switchMap(term => term.length < 2 ? []
      : this.httpService.getTeamsByName(term).pipe(map((result : [])=>{ this.foundTeams = result;
        return result.map((item: any) => item.name);
      }))));


  setSelectedTeam(team){
    this.selectedTeam = this.foundTeams.find(item=> team === item.name);
  }

  updateDisabledFields(){
    if(this.teamCreate){
      this.goalForm.controls.personalGoal.enable();
      this.goalForm.controls.teamName.enable();
      this.goalForm.controls.teamGoal.enable();
      this.goalForm.controls.searchTeam.disable();
    }
    if(this.teamSearch){
      this.goalForm.controls.personalGoal.enable();
      this.goalForm.controls.teamName.disable();
      this.goalForm.controls.teamGoal.disable();
      this.goalForm.controls.searchTeam.enable();
    }
    if(this.individual){
      this.goalForm.controls.personalGoal.enable();
      this.goalForm.controls.teamName.disable();
      this.goalForm.controls.teamGoal.disable();
      this.goalForm.controls.searchTeam.disable();
    }
  }

  update(){
    
    this.updateDisabledFields();

    if(this.goalForm.invalid){
      this.goalForm.markAllAsTouched();
      return;
    }

    if(!this.teamCreate){
      this.updateCurrentParticipant();
    }else{
      this.httpService.createTeam({
        name: this.goalForm.controls.teamName.value,
        goal: this.goalForm.controls.teamGoal.value
      }).subscribe(result=>{
        this.selectedTeam = result;
        this.toastr.success("Team created");
        this.updateCurrentParticipant();
      }, (error)=>{

        this.toastr.error("Please try again");
      });

    }
  }

  updateCurrentParticipant(){
    this.httpService.updateParticipant({...this.currentParticipant, teamId: this.selectedTeam?.id, goal: this.goalForm.controls.personalGoal.value}, this.currentParticipant.id).subscribe((result: any)=>{
      this.toastr.success("Updated");
      if(result.ownedTeamId){
        this.router.navigate(['/participant', 'current', result.ownedTeamId]);
      }else{
        this.router.navigate(['/participant', 'current']);
      }
    }, (error)=>{

      this.toastr.error("Please try again");
    });
  }
}
