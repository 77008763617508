  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> Donations Table</h4>
        </div>
        <div class="card-body">

          <div class="table-responsive">
        <table class="table">
          <thead class="text-primary">
            <tr>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col">First</th>
              <th scope="col">Last</th>
              <th scope="col">Email</th>
              <th scope="col">Provience</th>
              <th scope="col">Team/Individaul</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let donation of data; let i = index">
              <th scope="row">${{donation.amount}}</th>
              <td>{{donation.created_at}}</td>
              <td>{{donation.firstName}}</td>
              <td>{{donation.lastName}}</td>
              <td>{{donation.email}}</td>
              <td>{{donation.state}}</td>
              <td>{{donation.donatedTo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" *ngIf="page > 1"><a class="page-link"
              [routerLink]="['/admin/donations', page-1]">Previous</a></li>

          <li *ngFor="let item of [].constructor(pages); let i = index" class="page-item">
            <a class="page-link" [routerLink]="['/admin/donations',i+1]">{{i+1}}</a>
          </li>
          <li class="page-item" *ngIf="page < pages"><a class="page-link"
              [routerLink]="['/admin/donations',page+1]">Next</a></li>
        </ul>
      </nav>
    </div>
  </div>
