<div class="row registeration-card">
    <div class="col-md-8 offset-md-2 col-sm-12">
        <div class="default" *ngIf="default">
            <div class="card text-center">
                <div class="card-header">
                    <h5 class="card-title">{{'pre-register-form.title' | translate}}</h5>
                    <p class="card-text">{{'pre-register-form.subtitle' | translate}}  </p>
                </div>
                <div class="card-body">
                    <div class="card">
                        <button class="border" (click)="toggleForms('login')">
                            <div class="row">
                                <div class="col-6">
                                    <img class="card-img"
                                        src="{{'images.register.src-login' | translate}}"
                                        alt="{{'images.register.alt-login' | translate}}">
                                </div>
                                <div class="col-6 text">
                                    <h5 class="card-title">{{'login-form.title' | translate}}</h5>
                                    <p class="card-text">{{'login-form.subtitle' | translate}}</p>
                                </div>
                            </div>
                        </button>
                    </div>
                    <div class="card">
                        <button class="border" (click)="toggleForms('registeration')">
                            <div class="row">
                                <div class="col-6">
                                    <img class="card-img"
                                        src="{{'images.register.src-register' | translate}}"
                                        alt="{{'images.register.alt-register' | translate}}">
                                </div>
                                <div class="col-6 text">
                                    <h5 class="card-title">{{'registeration-form.title' | translate}}</h5>
                                    <p class="card-text">{{'registeration-form.subtitle' | translate}}</p>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
        <app-login-form *ngIf="login" [verified]="verified"></app-login-form>
        <app-registeration-form *ngIf="registeration"></app-registeration-form>
        <button (click)="toggleForms('default')" type="submit" class="btn btn-primary ">{{'pre-register-form.previous-button' | translate}}</button>
    </div>
</div>