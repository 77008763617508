import { Component, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';
import { ActivatedRoute } from '@angular/router';


@AutoUnsub()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  login:boolean = false;
  
  registeration:boolean = false;
  
  default:boolean = true;

  verified = false;


  constructor(private appService: AppService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.verified = params['verified'];
      if(this.verified){
        this.toggleForms('login');
      }
  });
  }

  
  ngOnInit(): void {
    this.appService.setPageTitle('Registration');
  }

  toggleForms(input){
    this.default = false;
    input == 'login' ? this.login = true : this.login = false;
    input == 'registeration' ? this.registeration = true : this.registeration = false;
    input == 'default' ? this.default = true : this.default = false;
  }

}
