import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../..//Service/http.service';
import { PROFILE_PICTURE, COVER_IMAGE } from '../../constants';
import { AppService } from '../../Service/app.service';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-participant-profile',
  templateUrl: './participant-profile.component.html',
  styleUrls: ['./participant-profile.component.scss']
})
export class ParticipantProfileComponent implements OnInit {


  participant : any = {};

  subs = [];

  participantId;
  teamId;

  constructor(private route: ActivatedRoute, private httpService: HttpService,  private router: Router, private appService: AppService, private toastService: ToastrService) {
    this.subs.push(this.route.params.subscribe(params => {
      this.participantId = params['id'];
      this.teamId = params['teamId'];
      if(this.participantId && !this.teamId){
        this.subs.push(this.httpService.getParticipantById(this.participantId).subscribe(response=>{
          this.participant = response;
          this.redirectToFindIfNotFound();
        }))
      }else{
        this.subs.push(this.httpService.getTeamById(this.teamId).subscribe(response=>{
          this.participant = response;
          this.redirectToFindIfNotFound();
        }))
      }

   }));
  }

  ngOnInit(): void {

  }

  redirectToFindIfNotFound(){
    this.appService.setPageTitle(`${this.getName()} Profile`);
    if(!this.participant?.id){
      this.router.navigate(['/find'])
    }
  }

  getName(){
    if(this.teamId){
      return this.participant.name;
    }else{
      return this.participant.firstName+ ' '+ this.participant.lastName;
    }
  }

  donate(){
    if(this.teamId){
      this.router.navigate(['/donate', this.participantId, this.teamId]);
    }else{
      this.router.navigate(['/donate', this.participantId]);
    }
  }
  
  getImage(participant){
    if(!participant.profilePicture){
      return PROFILE_PICTURE;
    }
    return participant.profilePicture
  }

  getCover(participant){
    if(!participant.cover){
      return COVER_IMAGE;
    }
    return participant.cover
  }

  updatePhoto($event, type){
    let form = new FormData();
    form.append('image', this.uploadFile($event));
    this.httpService.updateImage(form, type, this.participant.id, this.teamId).subscribe(result=>{
      this.participant = result;
      this.toastService.success('Updated successfuly');
    }, error=>{
      this.toastService.error('Error updating please try again');
    });
  }

  uploadFile(event) : any{
    return  (event.target as HTMLInputElement).files.length == 1 ?  (event.target as HTMLInputElement).files[0] : (event.target as HTMLInputElement).files;
  }

  updateDescription(){
    this.httpService.updateProfile({description: this.participant.description}, this.participant.id, this.teamId).subscribe((result)=>{
      this.toastService.success('Profile updated successfuly');
    })
  }

  goProfile(id, type){
    (type == 'team') ? this.router.navigate(['participant','teams',id]) : this.router.navigate(['participant/',id]);
  }

  getPercentage(){
    if(this.participant.total == 0){
      return '0%';
    }else{
      return ((this.participant.total/this.participant.goal) *100) + '%';
    }
  }

}
