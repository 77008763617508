<div class="card text-center">
    <div class="card-header">
        <h3 class="card-title">{{'login-form.title' | translate}} </h3>
    </div>
    <div class="card-body">
        <form [formGroup]="loginForm">
            <div class="row">
            <div class="col-12">
                <label class="sr-only" for="email">{{'form.email' | translate}}</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="userIcon"></fa-icon></div>
                    </div>
                    <input type="email" class="form-control" name="email" id="email" placeholder="{{'form.email' |  translate}}" formControlName="email">
                </div>
                <app-error-message  ngDefaultControl [formControl]="loginForm.controls.email" [messages]="{required: 'error.email' | translate}"></app-error-message>
            </div>
            </div>

            <div class="row">
            <div class="col-12">
                <label class="sr-only" for="password">{{'form.password' | translate}}</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><fa-icon [icon]="lockIcon"></fa-icon></div>
                    </div>
                    <input type="password" class="form-control" name="password" id="password" placeholder="{{'form.password' | translate}}" formControlName="password">
                </div>
            </div>
            </div>
            <app-error-message  ngDefaultControl [formControl]="loginForm.controls.password" [messages]="{required: 'error.password' | translate}"></app-error-message>
            <div class="row">
                <div class="col">
                    <button type="submit" (click)="onSubmit()" class="btn btn-primary ">{{'login-form.button' | translate}}</button>
                    <p>{{'login-form.forgot-password' | translate}}
                        <a [routerLink]="['/forgot-password']">{{'login-form.reequest-password' | translate}}</a></p>
                </div>
            </div>

        </form>
    </div>
</div>