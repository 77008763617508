<form [formGroup]="formGroup">
<div class="row">
    <div class="col-md-8 offset-md-2 col-sm-12">
        <div class="row">
            <div class="col-12">
                <h2>{{'forget-your-password.title' |  translate}}</h2>
                <p>{{'forget-your-password.subtitle' |  translate}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="sr-only" for="email">{{'forget-your-password.form.email' |  translate}}</label>
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <!-- <div class="input-group-text"><fa-icon [icon]="userIcon"></fa-icon></div> -->
                    </div>
                    <input type="email" class="form-control" name="email" id="email" placeholder="{{'forget-your-password.form.email' |  translate}}" formControlName="email">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-error-message  ngDefaultControl [formControl]="formGroup.controls.email" [messages]="{required: 'forget-your-password.form.email.error' |  translate}"></app-error-message>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-lg btn-primary" (click)="submit()">{{'forget-your-password.submit' |  translate}}</button>
            </div>
        </div>
    </div>
</div>


</form>