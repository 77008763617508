<div class="card text-center">
    <div class="card-header">
        <p>{{'post-register.title' | translate}} {{currentParticipant.firstName}} </p>
    </div>
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <h4>{{'post-register.particpant-type' | translate}}</h4>
                <p>{{'post-register.particpant-type-subtitle' | translate}}</p>
            </div>

        </div>
        <div class="row row-margin">
            <div class="col-lg-12">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary"
                        (click)="whichForm('individual')">Individual</button>
                    <button type="button" class="btn btn-secondary" (click)="whichForm('TeamCreate')">Create New
                        Team</button>
                    <button type="button" class="btn btn-secondary" (click)="whichForm('TeamSearch')">Search For A
                        Team</button>
                </div>
            </div>
        </div>
        <form [formGroup]="goalForm" (submit)="onSubmit('teamCreate')">

            <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="personalGoal">Personal Goal</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon [icon]="dollarSign"></fa-icon>
                            </div>
                        </div>
                        <input type="text" class="form-control" id="personalGoal" placeholder="Personal Goal"
                            formControlName="personalGoal">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <app-error-message  ngDefaultControl [formControl]="goalForm.controls.personalGoal" [messages]="{required: 'post-register.personal-goal-error' | translate}"></app-error-message>
                </div>
            </div>
            <div *ngIf="teamCreate">
                <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="teamName">{{'post-register.team-name' | translate}}</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon [icon]="dollarSign"></fa-icon>
                            </div>
                        </div>
                        <input type="text" class="form-control" id="teamName" placeholder="{{'post-register.team-name' | translate}}"
                            formControlName="teamName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-error-message  ngDefaultControl [formControl]="goalForm.controls.teamName" [messages]="{required: 'post-register.team-name-error' | translate }"></app-error-message>
                    </div>
                </div>
                <div class="col-12">
                    <label class="sr-only" for="teamGoal">{{'post-register.team-goal' | translate}}</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon [icon]="dollarSign"></fa-icon>
                            </div>
                        </div>
                        <input type="text" class="form-control" id="teamGoal" placeholder="{{'post-register.team-goal' | translate}}"
                            formControlName="teamGoal">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-error-message  ngDefaultControl [formControl]="goalForm.controls.teamGoal" [messages]="{required: 'post-register.team-goal-error' | translate}"></app-error-message>
                    </div>
                </div>
            
            </div>
            
            </div>
            <div *ngIf="teamSearch">
                <div class="row">
                <div class="col-12">
                    <label class="sr-only" for="searchTeam">{{'post-register.search-team' | translate}}</label>
                    <div class="input-group mb-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <fa-icon [icon]="dollarSign"></fa-icon>
                            </div>
                        </div>
                        <input type="text" class="form-control" id="searchTeam" placeholder="{{'post-register.search-team' | translate}}"
                            formControlName="searchTeam" [ngbTypeahead]="search" [resultTemplate]="rt"> 
                    </div>
                </div>
            </div>
            <app-error-message  ngDefaultControl [formControl]="goalForm.controls.searchTeam" [messages]="{required: 'post-register.search-team-error' | translate}"></app-error-message>
            </div>
        </form>
        <div class="row">
            <div class="col-md-12">
                <button (click)="update()" class="btn btn-primary">{{'post-register.continue' | translate}}</button>
            </div>
        </div>
    </div>
</div>



<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r" [term]="t" (click)="setSelectedTeam(r)"></ngb-highlight>
</ng-template>