import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  onActivate(event) {
    window.scroll(0,0);
  }
  constructor() { }

  ngOnInit(): void {
  
  }
  
}
