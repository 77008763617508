import { Component, OnInit } from '@angular/core';
import { faUser, faLock, faUserTag, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from 'src/app/Service/http.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/Service/app.service';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { AutoUnsub } from 'src/auto-unsubscribe';


@AutoUnsub()
@Component({
  selector: 'app-registeration-form',
  templateUrl: './registeration-form.component.html',
  styleUrls: ['./registeration-form.component.scss']
})
export class RegisterationFormComponent implements OnInit {

  public readonly executionLog: OnExecuteData[] = [];

  userIcon = faUser;
  lockIcon = faLock;
  userTag = faUserTag;
  envelope = faEnvelope;

  registrationForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4)]),
    fullName: new FormControl('', [Validators.required, this.fullNameValidator]),
    email: new FormControl('', [Validators.required]),
    acceptPolicy: new FormControl(false, [Validators.requiredTrue])
  })

  constructor(private http: HttpService, private router: Router,     private recaptchaV3Service: ReCaptchaV3Service,
    private toastr: ToastrService, private appService: AppService) {
      console.log(this.executionLog);
  }

  fullNameValidator(formControl: FormControl){
      if(formControl.value && formControl.value.indexOf(' ') == -1){
        return {fullname: true};
      }
      return;
  }

  policy (formControl: FormControl){
    if(formControl.value && formControl.value == false){
      return {policy: true};
    }
    return;
  }

  ngOnInit(): void {
    this.appService.setPageTitle('Registration');
  }
  onSubmit() {
console.log(this.registrationForm);
    if (this.registrationForm.invalid) {
      this.registrationForm.markAllAsTouched();
      return;
    }
    var name = this.registrationForm.controls.fullName.value.split(" ");
      const firstName = name[0];
      delete name[0];
      const lastName = name.join(" ");
    var registerationValues = {
      'firstName': firstName,
      'lastName': lastName,
      'email': this.registrationForm.controls.email.value,
      'password': this.registrationForm.controls.password.value,
      
    }
    this.http.register(registerationValues).subscribe(
      (result: any) => {

        this.toastr.success("Please check your email to verify your email address");
        //localStorage.setItem('token', result.token);
        //this.router.navigate(['register', 'team']);
      },
      error => {
        if (error.error.message.includes("Duplicate entry")) {
          this.toastr.error("This Email is already registrered, Please try to login!");
        } else {
          this.toastr.error("An error happened please try again!");
        }
      }

    );
    return false;
  }

}
