import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpService } from '../Service/http.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../Service/app.service';
import { AutoUnsub } from 'src/auto-unsubscribe';

@AutoUnsub()
@Component({
  selector: 'app-forget-passowrd',
  templateUrl: './forget-passowrd.component.html',
  styleUrls: ['./forget-passowrd.component.scss']
})
export class ForgetPassowrdComponent implements OnInit {


  formGroup : FormGroup = new FormGroup({
    email: new FormControl('',[Validators.required])
  })

  constructor(private httpService: HttpService, private toastService: ToastrService, private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setPageTitle('Forgot Password');

  }

  submit(){
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid){
      this.httpService.forgotPassowrd(this.formGroup.value).subscribe(result=>{
        this.toastService.success("Please check your email.");
      }, (error)=>{
        this.toastService.error("Error, Please try again later.");
      });
    }
  }

}
