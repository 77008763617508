 <header id="header"  class="navbar navbar-light navbar-fixed-top navbar-expand-lg"> 
  
    <a class="navbar-brand" href="{{'images.logo.link' | translate}}" (click)="navbarCollapsed = true" target="_blank"><img src="{{'images.logo.src' | translate}}" alt="{{'images.logo.alt' | translate}}" width="50rem"/></a> 
   
    <button class="navbar-toggler navbar-toggler-right" type="button" (click)="navbarCollapsed = !navbarCollapsed" 
            [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"> 
      <span class="navbar-toggler-icon"></span> 
    </button> 
   
    <div class="navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarContent"> 
      <ul class="nav-menu navbar-nav mr-auto"> 
        <li [routerLinkActive]="['menu-active']" class="nav-item nav-link"><a [routerLink]="['/home']">{{'mainmenu.tab.home' | translate}}</a></li>
        <li [routerLinkActive]="['menu-active']" class="nav-item nav-link"><a [routerLink]="['/find']">{{'mainmenu.tab.donate' | translate}}</a></li>
        <li [routerLinkActive]="['menu-active']" class="nav-item nav-link menu-has-children"><a [routerLink]="['/about']">{{'mainmenu.tab.about-us' | translate}}</a></li>
        <li [routerLinkActive]="['menu-active']"  class="nav-item nav-link" *ngIf="!isLoggedIn()"><a [routerLink]="['/register']">{{'mainmenu.tab.login' | translate}}</a></li>
        <li [routerLinkActive]="['menu-active']" class="nav-item nav-link" *ngIf="isLoggedIn()"><a [routerLink]="['/participant/current']">{{'mainmenu.tab.profile' | translate}}</a></li>
        <li [routerLinkActive]="['menu-active']" class="nav-item nav-link" *ngIf="getTeamProfileId()"><a [routerLink]="['/participant/team',getTeamProfileId()]">My Team</a></li>
        <li class="nav-item nav-link" *ngIf="isLoggedIn()"><a (click)="logout()" href="javascript:void(0)">{{'mainmenu.tab.logout' | translate}}</a></li>
      </ul>
      <div class="nav-menu navbar-nav">
        <a (click)="useLanguage('en')" class="nav-item nav-link" href="javascript:void(0)">{{'mainmenu.tab.english' | translate}}</a>
        <a (click)="useLanguage('fr')" class="nav-item nav-link" href="javascript:void(0)">{{'mainmenu.tab.french' | translate}}</a>
      </div>
      <div class="nav-menu navbar-nav">
        <a href="admin" *ngIf='isAdmin()'>Admin</a>
</div>
    </div> 
      
  </header> 